<template>
  <div class="card-list">
    <page-content
      @selectionChange="selectionChange"
      @tableCellClick="handleTableCellClick"
      :isShowTooltip="false"
      :dataList="cardInfoList"
      :contentTableConfig="contentTableConfig"
      pageName="cardInfo"
      category="card"
    >
      <template #salePrice="scope"> ¥{{ scope.row.salePrice }} </template>
      <template #expireUseFlag="scope">
        <template v-if="scope.row.expireUseFlag == 1"> 可使用 </template>
        <template v-else> 不可使用 </template>
      </template>
      <template #kindId="scope">
        <div class="card-kind" v-if="scope.row.kindId == 3">
          <span class="el-tag el-tag--success el-tag--light">有限次卡</span>
        </div>
        <div class="card-kind" v-if="scope.row.kindId == 4">
          <span class="el-tag el-tag--warning el-tag--light">不限次卡</span>
        </div>
        <div class="card-kind" v-if="scope.row.kindId == 5">
          <span class="el-tag el-tag--danger el-tag--light">储值卡</span>
        </div>
        <div class="card-kind" v-if="scope.row.kindId == 6">
          <span class="el-tag el-tag--light">折扣卡</span>
        </div>
      </template>
      <template #timeLimitFlag="scope">
        <div v-if="scope.row.timeLimitFlag == 1">永久</div>
        <div v-if="scope.row.timeLimitFlag == 2">
          {{ dayjs(scope.row.fixStartTime).format("YYYY年MM月DD日") }}-{{
            dayjs(scope.row.fixEndTime).format("YYYY年MM月DD日")
          }}
        </div>
        <div v-if="scope.row.timeLimitFlag == 3">
          购买后{{ scope.row.timeLimitDays }}天内有效
        </div>
        <div v-if="scope.row.timeLimitFlag == 4">
          使用后{{ scope.row.timeLimitDays }}天内有效
        </div>
      </template>

      <template #handler="scope">
        <div class="handle-btns">
          <el-button plain size="mini" @click.stop="handleDetailClick(scope.row)" v-has="'goods:card:edit'"
            >编辑</el-button
          >
          <el-button plain size="mini" @click.stop="handleDistributeClick(scope.row)"
            >分配</el-button
          >
          <div class="handle-wrap">
            <template v-if="scope.row.cardStatus == 1">
              <el-switch active-value="1" inactive-value="0" v-model="active" />
            </template>
            <template v-else>
              <el-switch active-value="1" inactive-value="0" v-model="inactive" />
            </template>
            <div @click.stop="handleStateClick(scope.row)" class="el-switch-mask"></div>
          </div>
        </div>
      </template>
    </page-content>
    <div class="el-pagination">
      <el-pagination
        v-model:currentPage="queryInfo.current"
        v-model:page-size="queryInfo.size"
        :page-sizes="[10, 20, 30, 40]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="cardInfoCount"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <distribute-dialog
      :cardId="currentEditCardId"
      :dialogVisible="distributeDialogVisible"
      @changeDialogVisible="changeDistributeDialogVisible"
    >
    </distribute-dialog>
    <card-detail-dialog
      :cardId="currentCardId"
      :dialogVisible="detailDialogVisible"
      @changeDialogVisible="changeDetailDialogVisible"
    ></card-detail-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, onUnmounted, computed } from 'vue'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { contentTableConfig } from './config'
import { useRouter } from 'vue-router'
import { COMPANY_ID } from '@/common/constant'
import distributeDialog from './cpns/distribute-dialog/distribute-dialog'
import emitter from '@/utils/eventbus'
import { useStore } from 'vuex'
import dayjs from 'dayjs'
import cardDetailDialog from './cpns/card-detail-dialog/card-detail-dialog'
import { ElMessage } from 'element-plus'
import { updateCardState } from '@/service/main/card'

export default defineComponent({
  props: {

  },
  components: {
    PageContent,
    distributeDialog,
    cardDetailDialog,


  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const currentCardId = ref('')
    const cardInfoList = computed(() => store.state.card.cardInfoList)
    const cardInfoCount = computed(() => store.state.card.cardInfoCount)

    const pageDialogVisible = ref(false)
    const handleGoodsEditdDrawer = (flag) => {
      pageDialogVisible.value = flag
    }

    const detailDialogVisible = ref(false)
    const changeDetailDialogVisible = (flag) => {
      detailDialogVisible.value = flag
    }


    const handleTableCellClick = (value) => {
      currentCardId.value = value.cardId
      detailDialogVisible.value = true
    }



    const handleDetailClick = (item) => {
      router.push({
        path: '/main/card/update',
        query: {
          cardId: item.cardId
        }
      })
    }
    const currentEditCardId = ref('')

    const distributeDialogVisible = ref(false)
    const changeDistributeDialogVisible = flag => {
      distributeDialogVisible.value = flag
    }

    const handleDistributeClick = (item) => {
      currentEditCardId.value = item.cardId
      distributeDialogVisible.value = true

    }

    const queryInfo = reactive({
      keyword: '',
      kind: '',
      cardStatus: '',
      current: 1,
      size: 10
    })

    const handleSizeChange = pagesize => {
      queryInfo.size = pagesize
      initPage()
    }

    const handleCurrentChange = page => {
      queryInfo.current = page
      initPage()
    }

    const handleStateClick = async (item) => {
      let status
      if (item.cardStatus == 1) {
        status = 2
      } else {
        status = 1
      }
      const res = await updateCardState(item.cardId, COMPANY_ID, status)
      if (res.code == 0) {
        ElMessage({
          message: '操作成功!',
          type: 'success',
        })
        initPage()
      }

    }


    emitter.on('changeCardTopcontentRuleForm', (n) => {
      queryInfo.keyword = n.keyword
      queryInfo.kind = n.kind
      queryInfo.cardStatus = n.cardStatus

    })

    const selectCardList = ref([])
    const selectionChange = (value) => {
      selectCardList.value = value
    }

    emitter.on('cardTopContentBatchSetState', value => {
      if (selectCardList.value.length == 0) {
        ElMessage.error('请先选择卡!')
        return
      }
      let cardIds = []
      selectCardList.value.forEach(item => {
        cardIds.push(item.cardId)
      })
      let obj = {
        companyId: COMPANY_ID,
        status: value,
        cardIds: cardIds.join(),
      }
      store.dispatch('card/batchSetCardStateAction', obj)
    })

    const active = ref('1')
    const inactive = ref('0')
    emitter.on('tcRefreshCardPageContent', () => {
      initPage()
    })

    onUnmounted(() => {
      emitter.off('tcRefreshCardPageContent')
      emitter.off('cardTopContentBatchSetState')
      emitter.off('changeCardTopcontentRuleForm')

    })

    const initPage = () => {
      store.dispatch('card/getcardInfoListAction', { ...queryInfo })
    }
    initPage()


    return {
      handleCurrentChange,
      handleSizeChange,
      active,
      inactive,
      selectionChange,
      currentCardId,
      handleStateClick,
      queryInfo,
      handleDistributeClick,
      currentEditCardId,
      changeDistributeDialogVisible,
      distributeDialogVisible,
      contentTableConfig,
      handleDetailClick,
      COMPANY_ID,
      dayjs,
      handleGoodsEditdDrawer,
      pageDialogVisible,
      handleTableCellClick,
      detailDialogVisible,
      changeDetailDialogVisible,
      cardInfoList,
      cardInfoCount
    }

  }
})
</script>

<style scoped lang="less">
.card-list {
  margin-top: 20px;

  &:deep(.el-dialog) {
    min-width: 460px;
  }

  &:deep(.el-table__body) {
    font-size: 12px;
  }

  .card-kind {
    display: flex;

    img {
      width: 24px;
    }
  }

  .handle-btns {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .handle-wrap {
      position: relative;
      padding-left: 6px;
    }

    &:deep(.el-button) {
      margin-left: 0 !important;
      margin: 4px !important;
    }
  }

  .name {
    .user-avatar {
      i {
        font-size: 40px;
      }
    }
  }
}
</style>
