<template>
  <div class="top-content">
    <div class="left">
      <el-button @click="handleAddCardClick(0)" class="add-staff" type="primary" v-has="'goods:card:add'">添加卡项</el-button>
      <el-dropdown trigger="click">
        <el-button type="primary">
          批量操作<el-icon class="el-icon--right">
            <arrow-down />
          </el-icon>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="handleBatchSetStateClick(1)">上架</el-dropdown-item>
            <el-dropdown-item @click="handleBatchSetStateClick(2)">下架</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>

    <div class="right">
      <el-select v-model="ruleForm.kind" class="m-2" placeholder="卡分类" size="large">
        <el-option v-for="item in cardCategoryList" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-select v-model="ruleForm.cardStatus" class="m-2" placeholder="销售状态" size="large">
        <el-option v-for="item in stateOptionList" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-input @change="handleSearchChange" clearable class="search" prefix-icon="search" v-model="ruleForm.keyword"
        placeholder="输入卡项名称" />
      <el-button @click="handleSearchClick" class="add-staff" type="primary">筛选</el-button>
    </div>
  </div>
</template>

<script lang="js">
import store from '@/store'
import { defineComponent, reactive, ref, computed, watch } from 'vue'
import { useRouter } from 'vue-router'
import { stateOptionList } from './config'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import emitter from '@/utils/eventbus'
import { cardCategoryList } from './config'
export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const handleAddCardClick = (num) => {
      router.push('/main/card/add')
    }

    const ruleForm = reactive({
      keyword: '',
      kind: '',
      cardStatus: ''
    })

    watch(ruleForm, (n) => {
      emitter.emit('changeCardTopcontentRuleForm', { ...n })
    }, {
      deep: true
    })

    const stateValue = ref(1)
    const initPage = () => {
      store.dispatch('card/getCardCategoryListAction', COMPANY_ID)
    }
    initPage()

    const handleSearchChange = (value) => {
      if (value.length == 0) {
        emitter.emit('changeCardTopcontentRuleForm', { ...ruleForm })
        emitter.emit('tcRefreshCardPageContent')
      }
    }

    const handleSearchClick = () => {
      emitter.emit('tcRefreshCardPageContent')
    }

    const handleBatchSetStateClick = (value) => {
      emitter.emit('cardTopContentBatchSetState', value)
    }

    return {
      handleBatchSetStateClick,
      handleSearchClick,
      ruleForm,
      COMPANY_ID,
      cardCategoryList,
      handleAddCardClick,
      stateOptionList,
      stateValue,
      handleSearchChange
    }

  }
})
</script>

<style scoped lang="less">
.top-content {
  display: flex;
  background-color: white;
  justify-content: space-between;

  .left {
    .add-staff {
      margin-right: 10px;
    }

    .add-btns {
      height: 90px;

      div {
        line-height: 45px;
      }
    }
  }

  .right {
    .search {
      max-width: 180px;
      min-width: 150px;
      margin: 0 10px;
    }

    &:deep(.el-select) {
      margin-right: 10px;

      .el-input {
        max-width: 160px;
      }
    }
  }
}
</style>

<style lang="less">
.is-light {
  min-width: auto !important;

  .card-add-btns {
    cursor: pointer;

    &>div:nth-child(1) {
      border-bottom: 1px solid var(--borderColor);
    }

    div {
      line-height: 40px;
      text-align: center;
    }
  }
}
</style>





