export const contentTableConfig = {
  propList: [
    { prop: 'kindId', label: '类型', minWidth: '70', slotName: 'kindId' },
    {
      prop: 'cardName',
      label: '卡项',
      minWidth: '150',
      slotName: 'cardName'
    },
    {
      prop: 'salePrice',
      label: '售价',
      minWidth: '100',
      slotName: 'salePrice'
    },
    {
      prop: 'timeLimitFlag',
      label: '有效期',
      minWidth: '100',
      slotName: 'timeLimitFlag'
    },
    {
      prop: 'expireUseFlag',
      label: '过期后是否可使用',
      minWidth: '100',
      slotName: 'expireUseFlag'
    },
    {
      prop: 'saleCount',
      label: '总销量',
      minWidth: '120',
      slotName: 'saleCount'
    },

    {
      prop: 'shopListingCount',
      label: '在售门店',
      minWidth: '120',
      slotName: 'shopListingCount'
    },
    { label: '操作', minWidth: '150', slotName: 'handler' }
  ],
  showIndexColumn: false,
  showSelectColumn: true
}
