<template>
  <div class="card-list-box">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="card-list-content">
      <top-content></top-content>
      <card-list></card-list>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import TopContent from './cpns/top-content/top-content.vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import CardList from './cpns/card-list/card-list.vue'
import { breadcrumbList } from './config'
export default defineComponent({
  props: {

  },
  components: {
    Breadcrumb,
    TopContent,
    CardList
  },
  setup() {


    return {
      breadcrumbList
    }

  }
})
</script>

<style scoped lang="less">
.card-list-box {
  min-width: 1080px;

  &:deep(.is-horizontal) {
    display: none !important;
  }

  .card-list-content {
    margin: 20px;
    padding: 20px;
    background-color: white;
  }
}
</style>




