export const stateOptionList = [
  {
    label: '所有销售状态',
    value: ''
  },
  {
    label: '上架',
    value: 1
  },
  {
    label: '下架',
    value: 2
  }

]

export const cardCategoryList = [
  {
    label: '所有种类',
    value: ''
  },
  {
    label: '有限次卡',
    value: 3
  },
  {
    label: '无限次卡',
    value: 4
  },
  {
    label: '储值卡',
    value: 5
  },
  {
    label: '折扣卡',
    value: 6
  }
]
